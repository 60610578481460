import React from "react";
import Layout from "../components/_App/layout";
import SEO from "../components/_App/seo";
import Navbar from "../components/_App/NavbarStyleFour";
import Footer from "../components/_App/Footer";
import * as Icon from "react-feather";
import { Link } from "gatsby";

import Blog from "../assets/json/blog.json";

const sortedArticles = Blog.articles.sort((a, b) => b.id - a.id);

const Blog1 = () => (
    <Layout>
        <SEO title="Blog | APPIUS - Software House & Web Agency" />

        <Navbar />

        <div
            className="page-title-area overflow-hidden"
            style={{ alignContent: "center", textAlign: "center" }}
        >
            <div className="container">
                <div style={{ marginBottom: 50, textAlign: "start" }}>
                    <h2
                        style={{ color: "rgb(49, 48, 206)" }}
                        className="text-uppercase Varela container"
                    >
                        Il nostro blog
                    </h2>
                </div>
                <div className="row justify-content-center">
                    {sortedArticles.map((article) => (
                        <div key={article.id} className="col-lg-4 col-md-6">
                            <div className="single-blog-post">
                                <div className="blog-image">
                                    <Link to={"/" + article.page}>
                                        <img
                                            className={`${
                                                article.img.includes(
                                                    "/rAnimated"
                                                )
                                                    ? "rotate-img"
                                                    : ""
                                            }`}
                                            src={
                                                require("../assets/images" +
                                                    article.img).default
                                            }
                                            alt="image"
                                        />
                                    </Link>

                                    <div className="date">
                                        <Icon.Calendar /> {article.data}
                                    </div>
                                </div>

                                <div className="blog-post-content">
                                    <h3>
                                        <Link to={"/" + article.page}>
                                            {article.title}
                                        </Link>
                                    </h3>

                                    <p>{article.desc}</p>

                                    <Link
                                        to={"/" + article.page}
                                        className="read-more-btn"
                                    >
                                        Scopri di più <Icon.ArrowRight />
                                    </Link>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>

        <Footer />
    </Layout>
);

export default Blog1;
